@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.footer {
    font-family: var(--sparky-font-family-primary);
    font-size: var(--sparky-font-size-12);
    color: var(--sparky-color-neutral-900);
    width: 100%;
    background-color: var(--sparky-color-neutral-50);

    &__info-panel {
        font-size: size(1.5);
        padding: size(4) size(2) size(3);
        text-align: center;
        background-color: white;
    }

    &__container {
        width: 100%;
        padding: 0;
    }

    &__navigation.sparky-l-container {
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        &.sparky-l-container {
            padding: 0;
        }
    }

    &__cta-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: size(2);
        row-gap: size(3);

        .cta-images-container {
            display: flex;
            align-items: center;
            gap: size(4);

            img {
                @media all and (min-width: $sparky-bp-lg) {
                    max-width: 200px;
                }
            }
        }

        .social-icons-container {
            margin-top: size(1);
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: size(2);

            img {
                min-width: size(3);
            }
        }
    }

    .divider {
        margin: 0;
        border-top: 1px solid var(--sparky-color-neutral-200);
    }

    @media all and (min-width: $sparky-bp-sm-2) {
        &__info-panel {
            padding: size(4) size(2);
        }

        &__cta-container {
            flex-direction: row;
            justify-content: space-between;
            padding: size(4) size(4) 0 size(4);

            .social-icons-container {
                margin-top: 0;
                align-self: flex-start;

                img {
                    min-width: size(4);
                }
            }

            .cta-images-container {
                padding-left: 0 size(2);
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    @media all and (min-width: $sparky-bp-md) {
        &__info-panel {
            padding: size(4) size(4) size(3);
        }
    }

    @media all and (min-width: $sparky-bp-lg) {
        &__info-panel {
            padding: size(2) 0 size(4);
        }

        &__cta-container {
            padding: size(2);

            .social-icons-container {
                align-self: center;
            }
        }
    }
}
