@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.footer {
    &__secondary-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: size(1) size(2);
        flex-direction: column;
        column-gap: size(4);

        .copyright-section {
            text-align: left;
            width: 100%;
        }

        .secondary-navigation {
            display: flex;
            width: 100%;
            column-gap: 0;

            .sparky-c-footer-nav__list {
                width: 100%;
                flex-direction: column;

                .sparky-c-footer-nav__link,
                .sparky-c-footer-nav__link:hover {
                    display: flex;
                    column-gap: size(0.5);
                    font-size: var(--sparky-font-size-12);
                    color: var(--sparky-color-neutral-900);
                }
            }
        }
    }

    @media all and (min-width: $sparky-bp-sm-2) {
        &__secondary-menu {
            padding: 0 size(4);
            margin-top: size(-2);
        }
    }

    @media all and (min-width: $sparky-bp-lg) {
        &__secondary-menu {
            padding: size(1) size(2);
            margin-top: 0;
            flex-direction: row;

            .copyright-section {
                padding: size(1.5) 0;
                align-self: flex-start;
                max-width: 230px;
            }

            .secondary-navigation {
                justify-content: flex-end;
                padding: size(0.5) 0;

                .sparky-c-footer-nav__list {
                    width: unset;
                    flex-direction: row;

                    .sparky-c-footer-nav__item {
                        max-width: 200px;
                        margin: var(--sparky-size-base-unit);
                    }
                }
            }
        }
    }
}
