@import '@petsmart-ui/sparky/dist/scss/functions';

.site-preview-btn {
    position: fixed;
    bottom: size(4);
    right: size(4);
    z-index: 100;
    padding: 14px !important;
    box-shadow: var(--sparky-theme-box-shadow);

    &__outline {
        color: var(--sparky-theme-color-button-foreground) !important;
        border-color: var(--sparky-theme-color-button-border) !important;
        background: white !important;

        &:hover {
            background: #f4f8ff !important;
        }
    }
}
