@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.site-preview-pickers {
    display: grid;
    grid-template-columns: 1fr 100px;
    margin-block: size(1);
    gap: size(1.5);

    .sparky-c-text-input {
        height: 100%;
    }

    @media (max-width: $sparky-bp-md) {
        /* stylelint-disable selector-class-pattern */
        .SingleDatePicker_picker {
            max-width: 318px;
            margin-left: size(2);
        }
    }
}

.site-preview-description {
    color: #535353;
}
