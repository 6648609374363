@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.legacy-footer {
    background-color: #fafafa;

    &__max-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media all and (min-width: $sparky-bp-lg) {
            margin-bottom: to-rem(13px);
        }

        @media all and (max-width: $sparky-bp-md) {
            flex-direction: column;
            padding: 0;
        }

        &__links {
            width: 42%;
            display: flex;
            flex-direction: row;
            margin: 0;
            padding: 0;
            flex: 1 0 30%;

            @media all and (max-width: $sparky-bp-lg) {
                border-bottom: to-rem(1px) solid #f2f7fc; // no variable set
                width: 100%;
                max-width: to-rem(700px);
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: auto;
            }
        }
    }

    &__container__cta {
        gap: to-rem(50px);
        display: flex;
        justify-content: center;
        align-items: center;

        @media all and (max-width: $sparky-bp-lg) {
            gap: 0;
            padding: to-rem(14px);

            a {
                padding: to-rem(14px);
            }
        }
    }

    &__nav-links {
        margin: 0 to-rem(50px) 0 0;

        @media all and (max-width: $sparky-bp-md) {
            margin-left: to-rem(20px);
        }

        @media (min-width: $sparky-bp-md) and (max-width: $sparky-bp-lg) {
            margin-left: to-rem(35px);
        }

        @media all and (max-width: $sparky-bp-lg) {
            flex: 1;
            flex-basis: 30%;
            margin-right: 0;
            width: 50%;
        }

        display: flex;
        flex-direction: column;
        list-style: none;
        padding-left: 0;

        .sparky-c-footer-nav__item {
            margin: 0;
            margin-bottom: to-rem(5px);
            display: inline-block;

            a {
                text-decoration: none;
                color: #00112f; // no variable set
                font-family: var(--legacy-header-font-family);
                letter-spacing: 1px;

                &:hover {
                    color: var(--sparky-theme-color-text-link-foreground-hover);
                }
            }
        }
    }

    &__app-buttons {
        display: flex;
        justify-content: space-around;
        max-width: to-rem(400px);

        @media all and (min-width: $sparky-bp-lg) {
            display: none;
        }

        &:has(a) {
            margin: to-rem(25px) auto to-rem(50px);
        }
    }

    &__social-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding-bottom: to-rem(24px);

        @media all and (min-width: $sparky-bp-lg) {
            .footer__icon-link {
                margin-right: to-rem(20px);
            }
        }
    }

    &__secondary-menu {
        font-family: var(--legacy-header-font-family);
        max-width: to-rem(1240px);
        margin: auto;
        align-items: center;
        padding-top: to-rem(25px);
        padding-left: to-rem(7.5px);
        padding-bottom: to-rem(5px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        letter-spacing: to-rem(1px);

        @media all and (max-width: $sparky-bp-lg) {
            flex-direction: column;
        }

        .copyright-section {
            font-size: var(--sparky-font-size-14);
            color: var(--sparky-color-neutral-800);
        }

        .secondary-navigation {
            font-family: var(--legacy-header-font-family);
            line-height: to-rem(20px);

            &__link {
                font-family: var(--legacy-header-font-family);
                font-size: var(--sparky-font-size-14);
                color: #00112f;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: var(--sparky-theme-color-text-link-foreground-hover);
                }

                &:not(:last-child)::after {
                    content: '|';
                    margin: 0 to-rem(5px);
                }

                img {
                    margin-left: to-rem(5px);
                    display: inline-block;
                    height: auto !important;
                }
            }
        }
    }

    &__terms-text {
        background-color: #abaeba;

        &--section {
            max-width: to-rem(820px);
            margin: 0 auto;
            color: var(--sparky-color-neutral-white);
            padding: to-rem(30px) to-rem(35px);
            line-height: to-rem(16px);
            font-size: var(--sparky-font-size-12);
            font-family: proximanovaa-regular, sans-serif;

            .sparky-c-text-passage {
                margin-top: 0;
                margin-bottom: to-rem(20px);

                &__inner {
                    font-size: var(--sparky-font-size-12);
                    line-height: to-rem(16px);
                    font-family: proximanovaa-regular, sans-serif;
                }

                a {
                    font-family: proximanovaa-regular, sans-serif;
                    font-size: to-rem(12px);
                    line-height: to-rem(16px);
                    color: var(--legacy-header-color-loyal-blue);

                    &:hover {
                        color: #206ef6;
                    }
                }
            }

            .sparky-l-linelength-container {
                max-width: none;
            }
        }
    }

    &__social {
        display: flex;
        flex-direction: column;

        &-callout {
            font-family: proximanovaa-regular, sans-serif;
            font-size: var(--sparky-font-size-16);
            color: var(--legacy-header-color-loyal-blue);
            font-weight: 600;
            margin-bottom: to-rem(40px);

            @media all and (max-width: $sparky-bp-lg) {
                display: none;
            }
        }
    }

    &__main-wrapper {
        @media all and (min-width: $sparky-bp-md) {
            padding: to-rem(30px) to-rem(15px) 0 to-rem(30px);
        }

        @media all and (max-width: $sparky-bp-md) {
            padding-top: to-rem(25px);
        }

        max-width: to-rem(1240px);
        margin: auto;
    }
}

.canada__footer {
    .legacy-footer {
        &__main-wrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;

            @media all and (max-width: $sparky-bp-lg) {
                width: 100%;
                align-items: center;
                flex-direction: column;
                justify-content: flex-start;
                padding: 0;
            }

            .legacy-footer__social {
                width: 28%;
                align-items: flex-start;

                @media all and (max-width: $sparky-bp-lg) {
                    width: 100%;
                    align-items: center;
                    max-width: to-rem(400px);
                }
            }

            .legacy-footer__max-wrapper {
                width: 43%;

                @media all and (max-width: $sparky-bp-lg) {
                    width: 100%;
                    padding-top: to-rem(50px);
                }
            }

            .legacy-footer__max-wrapper__links {
                .legacy-footer__nav-links {
                    margin-right: 20%;

                    @media all and (max-width: $sparky-bp-lg) {
                        margin-right: 0;
                        margin-left: 5%;
                        padding: to-rem(25px) 0;
                    }

                    .sparky-c-footer-nav__item {
                        margin-bottom: to-rem(25px);
                    }
                }
            }
        }

        &__max-wrapper {
            margin: 0;
        }

        @media all and (max-width: $sparky-bp-lg) {
            &__app-buttons:has(a) {
                margin-bottom: to-rem(25px);
                max-width: to-rem(400px);
                width: 100%;
                justify-content: space-around;
            }
        }
    }
}
