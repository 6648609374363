@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.aos__banner {
    font-family: var(--sparky-font-family-primary);
    border: solid 3px red;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: to-rem(20px);
    background-color: var(--sparky-color-brand-red-500);
    color: var(--sparky-color-neutral-50);
    font-weight: bold;
    font-size: to-rem(14px);
    padding: to-rem(2px) 0;

    @media all and (max-width: $sparky-bp-md) {
        font-size: to-rem(12px);
    }

    p {
        padding: 0;
        margin: 0;
    }

    div {
        width: to-rem(2px);
        height: to-rem(20px);
        background-color: var(--sparky-color-neutral-50);
    }
}
