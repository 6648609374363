.devtool {
    position: relative;
}

.icon {
    position: absolute;
    bottom: -34px;
    left: 16px;
    width: 35px;
    height: 35px;
    background: #282828;
    border: none;
    z-index: 1;
    display: grid;
    place-content: center;
    font-size: 1.2rem;
    border-radius: 0 0 4px 4px;
    cursor: pointer;

    & span {
        transition: transform 0.6s ease-in-out;
    }

    &.open span {
        transform: rotate(180deg);
    }
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background: #282828;
    color: #fe8d59;
    padding: 16px;
    gap: 16px;
}

.key {
    grid-column: span 3;

    & span {
        overflow: scroll;
        display: block;
    }
}

.title {
    font-family: monospace;
    color: #7cacf8;
}

.subtitle {
    color: #a8c7fa;
    text-transform: lowercase;
    font-size: 14px;
    background: #004a76;
    align-content: start;
    width: fit-content;
    padding: 2px 4px;
    border-radius: 2px;
    margin: 14px 0 0;
}

.experience {
    color: #7cacf8;
    border: 1px solid;
    width: fit-content;
    border-radius: 4px;
    display: flex;
    overflow: hidden;

    input {
        display: none;
    }

    label {
        padding: 6px 8px;
        cursor: pointer;
        font-family: monospace;

        &:hover {
            background: #3c3c3c;
        }
    }

    label:has(input:checked) {
        background: #004a76;
    }
}
