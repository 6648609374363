@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.footer {
    &__main-menu.sparky-c-footer-nav {
        padding: 0;
        background-color: var(--sparky-color-neutral-50);
        color: var(--sparky-color-neutral-900);

        .categories {
            display: flex;
            flex-direction: column;
        }

        .sparky-c-accordion-panel {
            border-top: var(--sparky-theme-border-width) solid
                var(--sparky-theme-color-neutral-subtle-border);
        }

        .sparky-c-footer-nav__list {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 0;

            .sparky-c-footer-nav__link,
            .sparky-c-footer-nav__link:hover {
                color: var(--sparky-color-neutral-900);
            }

            & .sparky-c-heading {
                padding: 0;
            }

            .sparky-c-footer-nav__item {
                color: var(--sparky-color-neutral-900);
            }

            .sparky-c-footer-nav__item:first-child {
                margin: size(0.5) size(2);
            }

            .sparky-c-footer-nav__item:not(:first-child) {
                margin: size(2.5) size(2);
            }

            .sparky-c-accordion {
                width: 100%;
            }

            .sparky-c-accordion-panel__button {
                padding: size(1.5) size(2);
            }

            .sparky-c-accordion-panel.sparky-is-active {
                padding-bottom: 0;
            }

            .sparky-c-accordion-panel__body,
            .sparky-c-accordion-panel__header,
            .sparky-c-accordion-panel__button:focus,
            .sparky-c-accordion-panel__button:focus-visible,
            .sparky-c-accordion-panel.sparky-is-active
                .sparky-c-accordion-panel__button,
            .sparky-c-accordion-panel.sparky-is-active
                .sparky-c-accordion-panel__button:hover {
                background-color: var(--sparky-color-neutral-50);
            }

            .sparky-c-accordion-panel.sparky-is-active
                .sparky-c-accordion-panel__header {
                margin: 0;
            }
        }

        @media all and (min-width: $sparky-bp-sm-2) and (max-width: $sparky-bp-lg) {
            .sparky-c-footer-nav__list {
                .sparky-c-accordion-panel__button {
                    padding: size(1.5) size(4);
                }

                .sparky-c-accordion-panel__body {
                    padding: size(0) size(2);
                }
            }
        }

        @media all and (min-width: $sparky-bp-lg) {
            padding: size(3) 0;

            .sparky-c-footer-nav__list {
                .sparky-c-footer-nav__item:first-child {
                    margin: size(1) size(2);
                }

                .sparky-c-footer-nav__item:not(:first-child) {
                    margin: size(1) size(2);
                }

                .sparky-c-heading {
                    padding: size(1) size(2);
                }
            }
        }
    }
}
